import _baseFilter from "./base-filter";
import _getBlocksForRange from "./getBlocksForRange";
import _hexUtils from "./hexUtils";
var exports = {};
const BaseFilter = _baseFilter;
const getBlocksForRange = _getBlocksForRange;
const {
  incrementHexInt
} = _hexUtils;

class TxFilter extends BaseFilter {
  constructor({
    provider
  }) {
    super();
    this.type = "tx";
    this.provider = provider;
  }

  async update({
    oldBlock
  }) {
    const toBlock = oldBlock;
    const fromBlock = incrementHexInt(oldBlock);
    const blocks = await getBlocksForRange({
      provider: this.provider,
      fromBlock,
      toBlock
    });
    const blockTxHashes = [];

    for (const block of blocks) {
      blockTxHashes.push(...block.transactions);
    } // add to results


    this.addResults(blockTxHashes);
  }

}

exports = TxFilter;
export default exports;